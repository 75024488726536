.card {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}


.body{
    font-family: "Prompt" !important;
    background-color: #303a4c !important;

    height: 100vh;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
