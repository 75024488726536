.title-header {
  color: #145fad;
  font-weight: bold;
  text-align: center;
  margin-bottom: 16px;
}

.title-detail {
  color: #5dc2a6;
  text-align: center;
  margin-bottom: 16px;
}

.label-form {}

.buttom-form {
  border-radius: 18px;
  min-width: 130px;
  background-image: linear-gradient(90deg,
      rgba(93, 194, 166, 1) 0%,
      rgba(20, 95, 173, 1) 100%);
  border-style: initial;
}

.buttom-form:active {
  color: #096dd9;
  background: #fff;
  background-image: linear-gradient(90deg,
      rgba(93, 194, 166, 1) 0%,
      rgba(20, 95, 173, 1) 100%);
}

.buttom-form:hover,
.buttom-form:focus {
  color: #fff;
  background: #40a9ff;
  background-image: linear-gradient(90deg,
      rgba(93, 194, 166, 1) 0%,
      rgba(20, 95, 173, 1) 100%);
}

.label-error {
  color: red;
  font-weight: bold;
}

.mobile-input::-webkit-inner-spin-button,
.mobile-input::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

.mobile-input {
  -moz-appearance: textfield !important;
}

.countDown {
  color: #4a9781;
}

.text-pdpa {
  text-decoration: underline;
  color: #145fad;
  cursor: pointer;
}

.content {
  /* margin: 18px; */
  margin-top: 18px;
  margin-right: 18px;
}

.ant-btn,
.ant-btn:active,
.ant-btn:focus {
  min-width: 100px;
}

.red {
  color: red;
}

.ant-table-tbody>tr.ant-table-row-level-0:hover>td {
  background-color: #f5f5f5;
}

.ant-table-thead>tr>th {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 700;
  text-align: center;
  background: #ffffff;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;

  border-radius: 2px 2px 0 0;
  border-collapse: separate;
  border-spacing: 0;
  border-bottom: 1px solid #111;
}

.ant-table table {
  border-bottom: 1px solid black;
}

.btn-secondary:hover,
.btn-secondary:focus {
  color: #fff;
  background: #5a6268;
  border-color: #5a6268;
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-info:hover,
.btn-info:focus {
  color: #fff;
  background: #5a6268;
  border-color: #5a6268;
}


.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.page-breadcrumb {
  margin-bottom: 15px;
}

.ant-card-bordered {
  border: 1px solid #f0f0f0;
}

.ant-layout {
  background: #f9fafb;
}

.ant-modal-header-confirm {
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-bottom: 0px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}

.custom-quill-content .ql-align-center {
  text-align: center !important;
  display: block;
  width: 100%;
}