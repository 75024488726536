.navbar {
  /* background-color: white;
  padding-left: 2rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; */

  width: 100%;
  /* height: 60px; */
  background: #ffffff;
  border-bottom: 1px solid #f2f4f9;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  padding: 0;
  right: 0;
  left: 240px;
  z-index: 978;
  box-shadow: 3px 0 10px 0 rgb(183 192 206 / 20%);
  transition: width .1s ease, left .1s ease;
  border: 1px solid #f0f0f0;
}

.btn-menu {
  margin-left: 16px;
}

@media (min-width: 992px) {
  .btn-menu {
    display: none;
  }
}

.logo {
  height: 32px;
  margin-left: 1rem;
}

.avatar {
  float: right;
  margin-right: 18px;
  cursor: pointer;
}

.avatar-name {
  margin-right: 18px;
  font-size: 18px;
    font-weight: bold;
}

