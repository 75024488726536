/* 📌 บังคับให้ Quill แสดงชื่อฟอนต์ใน Dropdown */
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Arial"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Arial"]::before {
    content: "Arial";
    font-family: Arial;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Tahoma"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Tahoma"]::before {
    content: "Tahoma";
    font-family: Tahoma;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Courier"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Courier"]::before {
    content: "Courier";
    font-family: Courier;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Times-New-Roman"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Times-New-Roman"]::before {
    content: "Times New Roman";
    font-family: "Times New Roman";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Prompt"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Prompt"]::before {
    content: "Prompt";
    font-family: "Prompt";
}


/* 📌 บังคับให้เนื้อหาใช้ฟอนต์ที่เลือก */
.ql-editor .ql-font-Arial {
    font-family: Arial;
}

.ql-editor .ql-font-Tahoma {
    font-family: Tahoma;
}

.ql-editor .ql-font-Courier {
    font-family: Courier;
}

.ql-editor .ql-font-Times-New-Roman {
    font-family: "Times New Roman";
}

.ql-editor .ql-font-Times-New-Roman {
    font-family: "Prompt";
}

/* 📌 บังคับให้ Quill แสดงชื่อขนาดฟอนต์ใน Dropdown */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="10px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
    content: "10px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="12px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
    content: "12px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="14px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
    content: "14px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
    content: "16px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="18px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
    content: "18px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="24px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
    content: "24px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="32px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="32px"]::before {
    content: "32px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="36px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="36px"]::before {
    content: "36px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="48px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="48px"]::before {
    content: "48px";
}

/* 📌 บังคับให้เนื้อหาใช้ขนาดฟอนต์ที่เลือก */
.ql-editor span[style*="font-size: 10px"] {
    font-size: 10px !important;
}

.ql-editor span[style*="font-size: 12px"] {
    font-size: 12px !important;
}

.ql-editor span[style*="font-size: 14px"] {
    font-size: 14px !important;
}

.ql-editor span[style*="font-size: 16px"] {
    font-size: 16px !important;
}

.ql-editor span[style*="font-size: 18px"] {
    font-size: 18px !important;
}

.ql-editor span[style*="font-size: 24px"] {
    font-size: 24px !important;
}

.ql-editor span[style*="font-size: 32px"] {
    font-size: 32px !important;
}

.ql-editor span[style*="font-size: 36px"] {
    font-size: 36px !important;
}

.ql-editor span[style*="font-size: 48px"] {
    font-size: 48px !important;
}

.ql-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 8px;
}

.ql-toolbar .ql-font {
    flex: 0 1 auto;
    max-width: 200px;
}

.ql-toolbar .ql-font .ql-picker-label,
.ql-toolbar .ql-font .ql-picker-item {
    min-width: 140px;
    white-space: nowrap;
    text-align: left;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ql-toolbar .ql-size {
    flex: 0 1 auto;
    min-width: 80px;
}

.custom-quill .ql-container {
    min-height: 200px;
    max-height: 500px;
    overflow-y: auto;
}