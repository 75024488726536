.number-title-32{
    margin-left: 32px;
}
.number-title-48{
    margin-left: 48px;
}

.number-title-70{
    margin-left: 70px;
}

